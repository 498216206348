import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import { Provider } from "react-redux";

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages, layoutMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';

// import Context
import SocketContext from '../contexts/SocketContext';

// import config
import { socket } from '../config/socketConnectivity';

// import lib
import isLogin from '../lib/isLogin';
import { decodeJwt } from '../lib/jsonWebToken';
import { getAuthToken } from '../lib/localStorage';

// import route
import HelperRoute from '../Route/HelperRoute';

// import redux-store
import store from '../store';

const App = () => {
	// getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	const { isAuth } = store.getState().auth;

	useEffect(() => {
		if (isAuth != true && isLogin()) {
			let token: any = getAuthToken()
			decodeJwt(token, store.dispatch)
		}
	}, [])

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, demoPages.forgotpassword.path, demoPages.resetPassword.path];
	
	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<SocketContext.Provider value={{ socket }}>
					<ToastProvider components={{ ToastContainer, Toast }}>
						<TourProvider
							steps={steps}
							styles={styles}
							showNavigation={false}
							showBadge={false}>
							<div
								ref={ref}
								className='app'
								style={{
									backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
									zIndex: fullScreenStatus ? 1 : undefined,
									overflow: fullScreenStatus ? 'scroll' : undefined,
								}}>
								<Routes>
									{withOutAsidePages.map((path) => (
										<Route key={path} path={path} />
									))}
									<Route path='*' element={<Aside />} />
								</Routes>
								<Wrapper />
								<HelperRoute />
							</div>
							<Portal id='portal-notification'>
								<ReactNotifications />
							</Portal>
						</TourProvider>
					</ToastProvider>
				</SocketContext.Provider>
			</ThemeProvider>
		</Provider>
	);
};

export default App;
