// import lib
import isEmpty from './isEmpty';
import config from '../config'
export const memberShipStatus = (balance: any, data: any) => {
    try {

        let memberShip = '-';
        if (isEmpty(data)) {
            return memberShip;
        }
        console.log("...1",data,balance)

        for (let item of data) {
            if (balance >= item.fromBalance && balance <= item.toBalance) {
                memberShip = item.name
                break;
            }
            else if (balance >= item.fromBalance && item.name == 'Premium Plus Partner') {
                memberShip = item.name
                break;
            }
            //  else if (balance >= item.fromBalance && item.name == 'Diamond') {
            //     memberShip = item.name
            //     break;
            // }
        }

        return memberShip;

    } catch (err: any) {
        console.log(err, 'errerr')
        return '-'
    }
}

export const levelStatus = (level: any) => {
    try {
        let spilitLvl = level.split('level');
        level = 'Level ' + spilitLvl[1];
        return level;
    } catch (err) {
        return level
    }
}

export const notifyClass = (type: any) => {
    try {
        switch (type) {
            case 'LOGIN' : return 'ViewInAr';
            case 'CHANGE_PASSWORD' : return 'ThumbUp';
            case 'BUY_TOKEN' : return 'Inventory2';
            case 'DEPOSIT' : return 'BakeryDining';
            case 'WITHDRAW_REQUEST' : return 'Escalator';
            case 'WITHDRAW_APPROVED' : return 'ViewInAr';
            case 'REFERRAL_EARN' : return 'ThumbUp';
            // case 'MEMBER_SHIP' : return 'Inventory2';
            // case 'REFERRAL_EARN' : return 'BakeryDining';
            case 'CHANGE_PASSSWORD' : return 'Inventory2';
            case 'NEW_REFERRAL' : return 'Escalator';
            case 'BUY_TOKEN' : return 'ViewInAr';
            default: return 'Escalator';
        }
    } catch (err) {
        return 'Escalator'
    }
}

export const notifyColor = (type: any) => {
    try {
        switch (type) {
            case 'LOGIN' : return 'info';
            case 'CHANGE_PASSWORD' : return 'warning';
            case 'BUY_TOKEN' : return 'danger';
            case 'DEPOSIT' : return 'success';
            case 'WITHDRAW_REQUEST' : return 'primary';
            case 'WITHDRAW_APPROVED' : return 'info';
            case 'REFERRAL_EARN' : return 'warning';
            // case 'MEMBER_SHIP' : return 'Inventory2';
            // case 'REFERRAL_EARN' : return 'BakeryDining';
            case 'CHANGE_PASSSWORD' : return 'danger';
            case 'NEW_REFERRAL' : return 'success';
            case 'BUY_TOKEN' : return 'primary';
            default: return 'info';
        }
    } catch (err) {
        return 'info'
    }
}


export const referalStatus=(type:any)=>{
    try {
        switch (type) {
            case 'completed' : return 'Completed';
            case 'pending' : return 'Pending';
            case 'rejected' : return 'Rejected';
            case 'BUY_TOKEN' : return 'danger';
            default: return '';
        }
    } catch (err) {
        return 'info'
    }
}

export const referalLevel=(level:any)=>{
    try {
        switch (level) {
            case '1' : return 'level1';
            case '2' : return 'level2';
            case '3' : return 'level3';
            case '4' : return 'level4';
            default: return '';
        }
    } catch (err) {
        return 'info'
    }
}

export const capitalize = (value:any) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
    return ''
  }

  export const showTrxHash=(coin:any,hash:any)=>{
    try {
        if(["BTC"].includes(coin))
        return `${config.BTC_TRX_SCAN}${hash}`
        else 
        return `${config.TRX_SCAN}${hash}`
    } catch (err) {
        return '-'
    }
  }