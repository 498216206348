// import lib
import isEmpty from "../../../lib/isEmpty";

export const registerValid = (reqBody: any) => {
  let errors: any = {};
  let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/g;
  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  if (isEmpty(reqBody.email)) {
    errors.email = "Email field is required";
  } else if (!emailRegex.test(reqBody.email)) {
    errors.email = "Email is invalid";
  }

  if (isEmpty(reqBody.firstName)) {
    errors.firstName = "First Name field is required";
  }

  if (isEmpty(reqBody.lastName)) {
    errors.lastName = "Last Name field is required";
  }

  if (isEmpty(reqBody.password)) {
    errors.password = "Password field is required";
  } else if (!passwordRegex.test(reqBody.password)) {
    errors.password =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18";
  } else if (reqBody.password.length > 18) {
    errors.password =
      "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18";
  }
  
  // if (isEmpty(reqBody.referralCode)) {
  //   errors.referralCode = "ReferralCode field is required";
  // }
  return errors;
};

export const loginValidate = (reqBody: any) => {
  let errors: any = {};
  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  if (isEmpty(reqBody.email)) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(reqBody.email)) {
    errors.email = "Invalid Email";
  }

  if (isEmpty(reqBody.password)) {
    errors.password = "Password is required";
  }

  return errors;
};
