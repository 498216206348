let key: any = {};

if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://api.kada.market";

  key = {
    API_URL: API_URL,
    SOCKET_URL: API_URL,
    FRONT_URL: "https://www.kada.market",
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    getGeoInfo: "https://ipapi.co/json/",
    SITE_NAME: "KaneDama",
    CRYPTO_SECRET_KEY: "1234567812345678",
    TRX_SCAN: "https://etherscan.io/tx/", //mainnet :  https://goerli.etherscan.io/tx/
    BTC_TRX_SCAN:"https://www.blockchain.com/btc/tx/"

  };
} else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set Demo Config");
  const API_URL = "https://api.disatoktest.xyz";

  key = {
    API_URL: API_URL,
    SOCKET_URL: API_URL,
    FRONT_URL: "https://disatoktest.xyz",
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    getGeoInfo: "https://ipapi.co/json/",
    SITE_NAME: "KaneDama",
    CRYPTO_SECRET_KEY: "1234567812345678",
    TRX_SCAN: "https://goerli.etherscan.io/tx/", //mainnet :https://etherscan.io/tx
    BTC_TRX_SCAN:"https://www.blockchain.com/btc/tx/"

  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  const PORT = 2053;
  key = {
    API_URL: `${API_URL}:${PORT}`,
    SOCKET_URL: `${API_URL}:${PORT}`,
    FRONT_URL: "http://localhost:3000",
    getGeoInfo: "https://ipapi.co/json/",
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    SITE_NAME: "KaneDama",
    CRYPTO_SECRET_KEY: "1234567812345678",
    TRX_SCAN: "https://goerli.etherscan.io/tx/", //mainnet :https://etherscan.io/tx
    BTC_TRX_SCAN:"https://www.blockchain.com/btc/tx/"

  };
}

export default key;
