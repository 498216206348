interface Fntypr {
    getAuthToken: () => string | null
}

export const setAuthToken = (token: string) => {
    localStorage.setItem('user_token', token);
    return true
}

export const getAuthToken = () => {
    if (localStorage.getItem('user_token')) {
        return localStorage.getItem('user_token')
    }
    return '';
}

export const setReferalCode = (code: any) => {
    localStorage.setItem('referalCode', code);
    return true
}

export const getReferalCode = () => {
    if (localStorage.getItem('referalCode')) {
        return localStorage.getItem('referalCode')
    }
    return '';
}

export const removeReferalCode = () => {
    return localStorage.removeItem('referalCode');
}

export const removeAuthToken = () => {
    localStorage.removeItem('user_token');
}

// export const getAuthToken =(): String|null => {
//     // localStorage.setItem("user_token","hello")
//     if (localStorage.getItem('user_token')) {
//         return localStorage.getItem('user_token')
//     }
//     return '';
// }