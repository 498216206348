import React, { FC, useCallback, useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import Toasts from '../../../components/bootstrap/Toasts';

//import action
import { resetPassword, verifyMailToken } from '../../../Action/user';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';


interface ILoginProps {
	isSignUp?: boolean;
}
type QuizParams = {
	authToken: string;
};

const initialFormValue: any = { password: '', confirmPassword: "", showPassword: false, showConfirmPassword: false, }
const ResetPassword: FC<ILoginProps> = ({ isSignUp }) => {

	const { addToast } = useToasts();

	const { darkModeStatus } = useDarkMode();
	const { authToken } = useParams<QuizParams>();
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const [formValue, setFormValue] = useState<any>(initialFormValue)
	const [errors, setErrors] = useState<any>({})
	const { password, confirmPassword, showPassword, showConfirmPassword } = formValue

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleChange = (e: any) => {

		const { name, value } = e.target;
		setFormValue({ ...formValue, ... { [name]: value } })
	}
	const handleSubmit = async (e: any) => {
		try {
			setIsLoading(true);

			let data = { password, confirmPassword, authToken }
			let { status, message, error } = await resetPassword(data);
			if (status == 'success') {
				addToast(
					<Toasts
						title='Success'
						icon='VerifiedUser'
						iconColor='success' // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
						time='Now'
						isDismiss>
						{message}
					</Toasts>,
					{
						autoDismiss: true
					}
				)
				setFormValue({})
				navigate(`/login`)
			} else {
				if (message) {
					addToast(
						<Toasts
							title='Error'
							icon='VerifiedUser'
							iconColor='danger' // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
							time='Now'
							isDismiss>
							{message}
						</Toasts>,
						{
							autoDismiss: true
						}
					)
					setErrors({})
					return
				}
				setErrors(error)
			}

			setIsLoading(false);

		} catch (err) {
			console.log("...err", err)
			setIsLoading(false);
		}

	}

	const verifyLink = async () => {
		let { status, message } = await verifyMailToken({authToken});

		if(status == 'failed'){
			addToast(
				<Toasts
					title='Error'
					icon='VerifiedUser'
					iconColor='danger' // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
					time='Now'
					isDismiss>
					{message}
				</Toasts>,
				{
					autoDismiss: true
				}
			)
			navigate(`/login`)
		}
	}

	// useEffect(() => {
	// 	setFormValue(initialFormValue)
	// 	setErrors({})
	// }, [singUpStatus])

	useEffect(() => {
		if(authToken){
			verifyLink()
		}
	}, [authToken])

	return (
		<PageWrapper isProtected={false} title="Reset Password" className="login_wrapper">
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5 login_logo'>
									<a
										href='https://www.kanedama.com/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo />
									</a>
								</div>


								<div className='text-center h1 fw-bold mt-5'>Reset Password</div>
								{/* <div className='text-center h4 text-muted mb-5'>Enter your registered Email Address</div> */}

								<form className='row g-4'>
									<>
										<div className='col-12'>
											<InputGroup>
												<FormGroup
													id='loginPassword'
													isFloating
													label='New Password'>
													<Input
														type={showPassword ? "text" : "password"}
														autoComplete='password'
														value={password}
														name={'password'}
														onChange={handleChange}

													/>
												</FormGroup>
												<Button color='dark'
													onClick={(e) => {
														setFormValue((el: any) => {
															return {
																...el,
																...{
																	showPassword: !el.showPassword,
																},
															};
														});
													}}
												>
													<i
														className={classNames(
															"fa",
															{ "fa-eye": showPassword },
															{ "fa-eye-slash": !showPassword }
														)}
														aria-hidden="true"
													></i>
												</Button>
											</InputGroup>
											<span className='text-danger'>{errors && errors.password}</span>
										</div>
										<div className='col-12'>
											<InputGroup>
												<FormGroup
													id='loginPassword'
													isFloating
													label='Confirm Password'>
													<Input
														type={showConfirmPassword ? "text" : "password"}
														autoComplete='confirmPassword'
														value={confirmPassword}
														name={'confirmPassword'}
														onChange={handleChange}

													/>
												</FormGroup>
												<Button color='dark'
													onClick={(e) => {
														setFormValue((el: any) => {
															return {
																...el,
																...{
																	showConfirmPassword: !el.showConfirmPassword,
																},
															};
														});
													}}
												>
													<i
														className={classNames(
															"fa",
															{ "fa-eye": showConfirmPassword },
															{ "fa-eye-slash": !showConfirmPassword }
														)}
														aria-hidden="true"
													></i>
												</Button>
											</InputGroup>
											<span className='text-danger'>{errors && errors.confirmPassword}</span>

										</div>
										<div className='col-12'>
											<Button
												color='info'
												className='w-100 py-3 login_btn'
												onClick={handleSubmit}
											>
												{isLoading && (<Spinner isSmall inButton isGrow />)}
												Submit
											</Button>
											<div className='text-center mt-3'>
												<Link to='/auth-pages/login'
													className={classNames('text-decoration-none', {
														'link-light': singUpStatus,
														'link-dark': !singUpStatus,
													})}>
													Login
												</Link>
											</div>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ResetPassword;
