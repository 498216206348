// import packages
import axios, { handleResp } from '../config/axios';

// import config
import config from '../config';

// import constant
import { SET_USER_WALLET_LIST } from '../constant';

// import lib
import { getAuthToken } from '../lib/localStorage';


export const getAssetData = async (dispatch: any) => {
    try {
        let token: any = await getAuthToken()
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/getAssetsDetails`,
            'headers': { 'Authorization': token }
        });
        dispatch(userWalletList(respData.data.result))
        return true
    }
    catch (err: any) {
        handleResp(err, 'error')
        return false
    }
}

export const getUsrDeposit = async () => {
    try {
        let token: any = await getAuthToken()
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/getUsrDeposit`,
            'headers': { 'Authorization': token }
        });

        return {
            'status': 'success',
            'result': respData.data.result,
            'count': respData.data.result.count

        }

    }
    catch (err: any) {
        handleResp(err, 'error')
        return {
            'status': false,
            'message': 'Error on server'
        }
    }
}

export const getUsrWithdraw = async () => {
    try {
        let token: any = await getAuthToken()
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/getUsrWithdraw`,
            'headers': { 'Authorization': token }
        });

        return {
            'status': 'success',
            'result': respData.data.result,
            'count': respData.data.result.count

        }

    }
    catch (err: any) {
        handleResp(err, 'error')
        return {
            'status': false,
            'message': 'Error on server'
        }
    }
}

export const coinWithdrawReq = async (data: any) => {
    try {
        let token: any = await getAuthToken()
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/coinWithdraw`,
            'headers': { 'Authorization': token },
            data
        });

        return {
            'status': 'success',
            'message': respData.data.message
        }

    }
    catch (err: any) {
        handleResp(err, 'error')
        return {
            'status': 'failed',
            'message': 'Error on server',
            'error': err.response.data.errors
        }
    }
}

export const coinRequestVerify = async (data: any) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `${config.API_URL}/api/coinWithdraw`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err: any) {
        handleResp(err, 'error')

        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}
export const checkUsrDeposit = async () => {
    try {
        let token: any = await getAuthToken()
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/api/checkUsrDeposit`,
            'headers': { 'Authorization': token },
        });

        return {
            status: "success",
            loading: false,
            // message: respData.data.message,
        }
    }
    catch (err: any) {
        handleResp(err, 'error')

        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const userWalletList = (data: any) => {
    return {
        type: SET_USER_WALLET_LIST,
        data
    }
}

export const purchaseRequest = async (data: any) => {
    try {
        let token: any = await getAuthToken()
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/token-request`,
            'data': data,
            'headers': { 'Authorization': token },
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err: any) {
        // handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            'error': err.response.data.errors
        }
    }
}