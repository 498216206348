import { tokenVerify } from './jsonWebToken';
import { getAuthToken } from './localStorage';

const isLogin = () => {
    let token: any = getAuthToken();
    if (tokenVerify(token)) {
        return true;
    }
    return false;
}

export default isLogin;