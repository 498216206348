// import constant
import {
    SET_REFERAL_MEMBERS_LEVEL,
} from '../constant';

const initialValue = []

const referralMembersLev = (state = initialValue, action) => {
    switch (action.type) {

        case SET_REFERAL_MEMBERS_LEVEL:
            return [
                ...action.data
            ];
        default:
            return state;
    }
}

export default referralMembersLev;