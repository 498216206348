import React, { FC, useCallback, useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import queryString from "query-string";
import PropTypes, { any } from "prop-types";
import classNames from "classnames";
import browser from "browser-detect";

import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import { dashboardMenu } from "../../../menu";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import Logo from "../../../components/Logo";
import useDarkMode from "../../../hooks/useDarkMode";
import { useFormik } from "formik";
import AuthContext from "../../../contexts/authContext";
import request from "../../../Action/Api";
import USERS, {
  getUserDataWithUsername,
} from "../../../common/data/userDummyData";
import Spinner from "../../../components/bootstrap/Spinner";
import Alert from "../../../components/bootstrap/Alert";
import Toasts from "../../../components/bootstrap/Toasts";
import InputGroup, {
  InputGroupText,
} from "../../../components/bootstrap/forms/InputGroup";
import Select from "../../../components/bootstrap/forms/Select";

// import actions
import { createUser, login, getGeoInfoData } from "../../../Action/user";

// import validation
import { registerValid, loginValidate } from "./validation";

// import lib
import {
  setReferalCode,
  getReferalCode,
  removeReferalCode,
} from "../../../lib/localStorage";
import isEmpty from "../../../lib/isEmpty";

interface ILoginHeaderProps {
  isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
  if (isNewUser) {
    return (
      <>
        <div className="text-center h1 fw-bold mt-5">Create Account,</div>
        <div className="text-center h4 text-muted mb-5">
          Sign up to get started!
        </div>
      </>
    );
  }
  return (
    <>
      <div className="text-center h1 fw-bold mt-5">Welcome,</div>
      <div className="text-center h4 text-muted mb-5">Sign in to continue!</div>
    </>
  );
};

interface ILoginProps {
  isSignUp?: boolean;
}

const initialFormValue: any = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  twoFACode: "",
  referralCode: "",
};
const Login: FC<ILoginProps> = ({ isSignUp }) => {
  const { addToast } = useToasts();
  // const { setUser } = useContext(AuthContext);

  const { darkModeStatus } = useDarkMode();

  const [signInPassword, setSignInPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
  const [showTwoFA, setShowTowFA] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<any>(initialFormValue);
  const [errors, setErrors] = useState<any>({});
  const [toched, setToched] = useState<any>({});
  const [loginHistory, setLoginHistory] = useState({});
  const [checkValue, setCheckValue] = useState<any>(false);
  const {
    email,
    password,
    firstName,
    lastName,
    twoFACode,
    referralCode,
    showPassword,
  } = formValue;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  console.log(searchParams.get('referral'), "searchParamssearchParamssearchParams");

  // const { referral } = queryString.parse(location.search);
  const referral = searchParams.get('referral');

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name == "twoFACode") {
      if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
        return;
      }
    }
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (singUpStatus) {
      setErrors(registerValid(formData));
    } else if (!singUpStatus) {
      setErrors(loginValidate(formData));
    }
  };

  const handleBlur = (e: any) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handlenavigate = useCallback(
    () => navigate("/auth-pages/login"),
    [navigate]
  );

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let data = {
        email,
        password,
        firstName,
        lastName,
        referralCode,
        checkValue,
      };
      let { status, message, error } = await createUser(data);
      if (status == "success") {
        let referalCode = getReferalCode();
        if (referalCode) {
          removeReferalCode();
        }
        setIsLoading(false);
        addToast(
          <Toasts
            title="Success"
            icon="VerifiedUser"
            iconColor="success" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
            time="Now"
            isDismiss
          >
            {message}
          </Toasts>,
          {
            autoDismiss: true,
          }
        );
        setFormValue(initialFormValue);
        setCheckValue(false);
        setErrors({});
        handlenavigate();
      } else {
        setErrors(error);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const loginsubmit = async () => {
    try {
      setIsLoading(true);
      let data = { email, password, twoFACode, loginHistory };
      let { status, message, error } = await login(data, dispatch);
      if (status == "success") {
        // localStorage.setItem('Disatok_authUsername', result.email);
        localStorage.setItem("tourModalStarted", "shown");
        addToast(
          <Toasts
            title="Success"
            icon="VerifiedUser"
            iconColor="success" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
            time="Now"
            isDismiss
          >
            {message}
          </Toasts>,
          {
            autoDismiss: true,
          }
        );
        // window.location.href = "/dashboard"
        navigate(`${dashboardMenu.dashboard.path}`);
      } else if (status == "TWO_FA") {
        setShowTowFA(true);
        addToast(
          <Toasts
            title="Error"
            icon="VerifiedUser"
            iconColor="danger" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
            time="Now"
            isDismiss
          >
            {message}
          </Toasts>,
          {
            autoDismiss: true,
          }
        );
      } else {
        if (message) {
          addToast(
            <Toasts
              title="Error"
              icon="VerifiedUser"
              iconColor="danger" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
              time="Now"
              isDismiss
            >
              {message}
            </Toasts>,
            {
              autoDismiss: true,
            }
          );
          setErrors({});
          setIsLoading(false);
          return;
        }
        setErrors(error);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      const browserResult = browser();
      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipaddress: result.ip,
        region: result.region,
        broswername: browserResult.name,
        ismobile: browserResult.mobile,
        os: browserResult.os,
      });
    } catch (err) { }
  };
  const handleCheckBox = (e: any) => {
    const { name, checked } = e.target;
    setErrors({});
    setCheckValue(checked);
  };
  useEffect(() => {
    let initData = initialFormValue;
    let referalCode = getReferalCode();
    if (referalCode) {
      setSingUpStatus(true);
      initData = { ...initData, ...{ referralCode: referalCode } };
    }
    setFormValue(initData);
    setErrors({});
  }, []);

  useEffect(() => {
    getGeoInfo();
    if (referral) {
      setSingUpStatus(true);
      setReferalCode(referral);
      setFormValue({ ...formValue, ...{ 'referralCode': referral } })
      navigate("/login");
      // setFormValue({ ...formValue, ...{ 'referralCode': referral } })
    }
  }, [referral]);

  useEffect(() => {
    if (singUpStatus) {
      setErrors(registerValid(formValue));
    } else if (!singUpStatus) {
      setErrors(loginValidate({ email, password }));
    }
  }, [singUpStatus]);

  return (
    <PageWrapper
      isProtected={false}
      title={singUpStatus ? "Sign Up" : "Login"}
      className="login_wrapper"
    >
      <Page className="p-0">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark" data-tour="login-page">
              <CardBody>
                <div className="text-center my-5 login_logo">
                  <a
                    href="https://www.kanedama.com/"
                    className={classNames(
                      "text-decoration-none  fw-bold display-2",
                      {
                        "text-dark": !darkModeStatus,
                        "text-light": darkModeStatus,
                      }
                    )}
                  >
                    <Logo />
                  </a>
                </div>
                <div
                  className={classNames("rounded-3", {
                    "bg-l10-dark": !darkModeStatus,
                    "bg-dark": darkModeStatus,
                  })}
                >
                  <div className="row row-cols-2 g-3 pb-3 px-3 mt-0">
                    <div className="col">
                      <Button
                        color={darkModeStatus ? "light" : "dark"}
                        isLight={singUpStatus}
                        className="rounded-1 w-100"
                        size="lg"
                        onClick={() => {
                          setSignInPassword(false);
                          setSingUpStatus(!singUpStatus);
                          setErrors({});
                          setToched({});
                        }}
                      >
                        Login
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        color={darkModeStatus ? "light" : "dark"}
                        isLight={!singUpStatus}
                        className="rounded-1 w-100"
                        size="lg"
                        onClick={() => {
                          setSignInPassword(false);
                          setSingUpStatus(!singUpStatus);
                          setErrors({});
                          setToched({});
                        }}
                      >
                        Sign Up
                      </Button>
                    </div>
                  </div>
                </div>

                <LoginHeader isNewUser={singUpStatus} />

                <form className="row g-4">
                  {singUpStatus ? (
                    <>
                      <div className="col-12">
                        <FormGroup
                          id="loginemail"
                          isFloating
                          label="Your email"
                        >
                          <Input
                            type="email"
                            autoComplete="email"
                            value={email}
                            name={"email"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                        <span className="text-danger">
                          {toched.email && errors && errors.email}
                        </span>
                      </div>
                      <div className="col-12">
                        <FormGroup
                          id="loginUsername"
                          isFloating
                          label="First Name"
                        >
                          <Input
                            autoComplete="given-name"
                            value={firstName}
                            name={"firstName"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                        <span className="text-danger">
                          {toched.firstName && errors && errors.firstName}
                        </span>
                      </div>
                      <div className="col-12">
                        <FormGroup
                          id="loginsurname"
                          isFloating
                          label="Last Name"
                        >
                          <Input
                            autoComplete="family-name"
                            value={lastName}
                            name={"lastName"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                        <span className="text-danger">
                          {toched.lastName && errors && errors.lastName}
                        </span>
                      </div>
                      <div className="col-12">
                        <InputGroup>
                          <FormGroup
                            id="loginPassword"
                            isFloating
                            label="Password"
                          >
                            <Input
                              type={showPassword ? "text" : "password"}
                              // autoComplete='password'
                              value={password}
                              name={"password"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                          <Button
                            color="dark"
                            onClick={(e) => {
                              setFormValue((el: any) => {
                                return {
                                  ...el,
                                  ...{
                                    showPassword: !el.showPassword,
                                  },
                                };
                              });
                            }}
                          >
                            <i
                              className={classNames(
                                "fa",
                                { "fa-eye": showPassword },
                                { "fa-eye-slash": !showPassword }
                              )}
                              aria-hidden="true"
                            ></i>
                          </Button>
                        </InputGroup>

                        <span className="text-danger">
                          {toched.password && errors && errors.password}
                        </span>
                      </div>
                      <div className="col-12">
                        <FormGroup
                          id="referralCode"
                          isFloating
                          label="Referral Code (Required)"
                        >
                          <Input
                            type="text"
                            autoComplete="referralCode"
                            value={referralCode}
                            name={"referralCode"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                        <span className="text-danger">
                          {errors && errors.referralCode}
                        </span>
                      </div>
                      <div className="col-12">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="CheckValue"
                          name="CheckValue"
                          onChange={handleCheckBox}
                          checked={checkValue}
                        />
                        <label
                          className="form-check-label ms-2"
                          htmlFor="CheckValue"
                        >
                          I accept and agree
                          {/* to the{" "} */}
                          {/* <Link to={"/terms"}> Terms & Conditions </Link> */}
                        </label>
                        <br />
                        <span className="text-danger">
                          {errors && errors.checkValue}
                        </span>
                      </div>

                      <div className="col-12">
                        <Button
                          color="info"
                          className="w-100 py-3 login_btn"
                          onClick={handleSubmit}
                          isDisable={isEmpty(errors) ? false : true}
                        >
                          Sign Up
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <FormGroup
                          id="loginemail"
                          isFloating
                          label="Your email"
                        >
                          <Input
                            type="email"
                            autoComplete="email"
                            value={email}
                            name={"email"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                        <span className="text-danger">
                          {toched.email && errors && errors.email}
                        </span>
                      </div>
                      <div className="col-12">
                        <InputGroup>
                          <FormGroup
                            id="loginPassword"
                            isFloating
                            label="Your password"
                          >
                            <Input
                              autoComplete="given-password"
                              value={password}
                              type={showPassword ? "text" : "password"}
                              name={"password"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                          <Button
                            color="dark"
                            to={""}
                            onClick={(e) => {
                              setFormValue((el: any) => {
                                return {
                                  ...el,
                                  ...{
                                    showPassword: !el.showPassword,
                                  },
                                };
                              });
                            }}
                          >
                            <i
                              className={classNames(
                                "fa",
                                { "fa-eye": showPassword },
                                { "fa-eye-slash": !showPassword }
                              )}
                              aria-hidden="true"
                            ></i>
                          </Button>
                        </InputGroup>
                        <span className="text-danger">
                          {toched.password && errors && errors.password}
                        </span>
                      </div>
                      {showTwoFA && (
                        <div className="col-12">
                          <FormGroup id="twoFACode" isFloating label="2FA Code">
                            <Input
                              type="text"
                              autoComplete="twoFACode"
                              value={twoFACode}
                              name={"twoFACode"}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          <span className="text-danger">
                            {errors && errors.twoFACode}
                          </span>
                        </div>
                      )}

                      <div className="col-12">
                        <Button
                          color="info"
                          // isDisable={isLoading}
                          className="w-100 py-3 login_btn"
                          onClick={loginsubmit}
                          isDisable={isEmpty(errors) ? false : true}
                        >
                          {isLoading && <Spinner isSmall inButton />}
                          Login
                        </Button>
                      </div>
                      <div className="text-center">
                        <Link
                          to="/auth-pages/forgot-password"
                          className={classNames("text-decoration-none", {
                            "link-light": singUpStatus,
                            "link-dark": !singUpStatus,
                          })}
                        >
                          Forgot Password
                        </Link>
                      </div>
                    </>
                  )}
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
