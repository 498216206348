import React, { FC, useCallback, useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import Toasts from '../../../components/bootstrap/Toasts';

//import action
import { forgotPassword } from '../../../Action/user';


interface ILoginProps {
	isSignUp?: boolean;
}

const initialFormValue: any = { email: ''}
const ForgotPassword: FC<ILoginProps> = ({ isSignUp }) => {

	const { addToast } = useToasts();

	const { darkModeStatus } = useDarkMode();

	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const [formValue, setFormValue] = useState<any>(initialFormValue)
	const [errors, setErrors] = useState<any>({})
	const { email, password, name, surName } = formValue

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	
	const handleChange=(e:any)=>{

		const {name,value} =e.target;
		setFormValue({ ...formValue, ... { [name]: value } })
	}
	const handleSubmit=async (e:any)=>{
		try {
			setIsLoading(true);

			let data = { email }
			console.log("...data",data)

			let { status, message, error } = await forgotPassword(data);
			if(status == 'success'){
				addToast(
					<Toasts
						title='Success'
						icon='VerifiedUser'
						iconColor='success' // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
						time='Now'
						isDismiss>
						{message}
					</Toasts>,
					{
						autoDismiss: true
					}
				)
				setFormValue({})
				navigate(`/login`)
			}else{
				setErrors(error)
			}

			setIsLoading(false);
			
		} catch (err) {
			console.log("...err",err)
			setIsLoading(false);
		}

	}
	useEffect(() => {
		setFormValue(initialFormValue)
		setErrors({})
	}, [singUpStatus])

	return (
		<PageWrapper isProtected={false} title="Forgot Password" className="login_wrapper">
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5 login_logo'>
									<a
										href='https://www.kanedama.com/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo />
									</a>
								</div>
								

								<div className='text-center h1 fw-bold mt-5'>Forgot Password</div>
								<div className='text-center h4 text-muted mb-5'>Enter your registered Email Address</div>
								
								<form className='row g-4'>								
									<>
										<div className='col-12'>
											<FormGroup
												id='signup-email'
												isFloating
												label='Your email'>
												<Input type='email' 
												autoComplete='email'
												name='email'
												value={email}
												onChange={handleChange}
												/>											
											</FormGroup>
											<span className='text-danger'>{errors && errors.email}</span>
										</div>
										<div className='col-12'>											
											<Button color='info' className='w-100 py-3 login_btn' onClick={handleSubmit}> {isLoading && ( <Spinner isSmall inButton isGrow />)}Submit</Button>
											<div className='text-center mt-3'>
												<Link to='/auth-pages/login'
													className={classNames('text-decoration-none', {
														'link-light': singUpStatus,
														'link-dark': !singUpStatus,
													})}>
													Login
												</Link>												
											</div>
										</div>
									</>									
								</form>
							</CardBody>
						</Card>						
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ForgotPassword;
