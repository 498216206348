import React, { FC } from 'react';
import PropTypes from 'prop-types';
import Img from '../assets/logos/logo.png';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
	    <img src={Img} alt='Logo' className='img-fluid logo_img' />
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
