import React from "react";
import { useMeasure } from "react-use";
import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import { Link } from "react-router-dom";

const Footer = () => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const root = document.documentElement;
  root.style.setProperty("--footer-height", `${height}px`);

  const { darkModeStatus } = useDarkMode();

  return (
    <footer ref={ref} className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            {/* <span className='fw-light'>Copyright © 2022</span>  */}
            <ul className="footer_links">
              <li>
                <Link to="/terms">Terms</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-auto">
            <span className="fw-light me-2">Copyright © 2022</span>
            <a
              href="/"
              className={classNames("text-decoration-none", {
                "link-dark": !darkModeStatus,
                "link-light": darkModeStatus,
              })}
            >
              <small className="fw-bold">KaneDama</small>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
