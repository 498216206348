import React, { FC, useCallback, useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";
import classNames from "classnames";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import Logo from "../../../components/Logo";
import useDarkMode from "../../../hooks/useDarkMode";
import { useFormik } from "formik";
import AuthContext from "../../../contexts/authContext";
import request from "../../../Action/Api";
import USERS, {
  getUserDataWithUsername,
} from "../../../common/data/userDummyData";
import Spinner from "../../../components/bootstrap/Spinner";
import Alert from "../../../components/bootstrap/Alert";
import Toasts from "../../../components/bootstrap/Toasts";

// import actions
import {
  userEmailActivation,
  verifyOldEmail,
  verifyNewEmail,
} from "../../../Action/user";
import { coinRequestVerify } from "../../../Action/walletAction";

const EmailVerification: any = () => {
  const location = useLocation();
  const { authToken } = useParams();

  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [page, setPage] = useState<string>("loading");

  // const handlenavigate = useCallback(() => navigate('/auth-pages/login'), [navigate]);

  // function
  const emailActivation = async () => {
    const { status, message } = await userEmailActivation({
      userId: authToken,
    });
    if (status == "success") {
      addToast(
        <Toasts
          title="Success"
          icon="VerifiedUser"
          iconColor="success" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
          time="Now"
          isDismiss
        >
          {message}
        </Toasts>,
        {
          autoDismiss: true,
        }
      );
      navigate("/auth-pages/login");
    } else if (status == "failed") {
      addToast(
        <Toasts
          title="Error"
          icon="VerifiedUser"
          iconColor="danger" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
          time="Now"
          isDismiss
        >
          {message}
        </Toasts>,
        {
          autoDismiss: true,
        }
      );
      navigate("/auth-pages/login");
    } else {
      setPage("error");
    }
  };

  const acceptCoinRequest = async () => {
    try {
      const { status, message } = await coinRequestVerify({ token: authToken });
      if (status == "success") {
        addToast(
          <Toasts
            title="Success"
            icon="VerifiedUser"
            iconColor="success" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
            time="Now"
            isDismiss
          >
            {message}
          </Toasts>,
          {
            autoDismiss: true,
          }
        );
        navigate("/withdraw");
      } else {
        addToast(
          <Toasts
            title="Error"
            icon="VerifiedUser"
            iconColor="danger" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
            time="Now"
            isDismiss
          >
            {message}
          </Toasts>,
          {
            autoDismiss: true,
          }
        );
        setPage("error");
        navigate("/withdraw");
      }
    } catch (err) {
      setPage("error");
      navigate("/withdraw");
    }
  };
  const verifOldEmail = async () => {
    const { status, message } = await verifyOldEmail({ token: authToken });
    if (status == "success") {
      addToast(
        <Toasts
          title="Success"
          icon="VerifiedUser"
          iconColor="success" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
          time="Now"
          isDismiss
        >
          {message}
        </Toasts>,
        {
          autoDismiss: true,
        }
      );
      navigate("/settings/profile-settings");
    } else {
      addToast(
        <Toasts
          title="Error"
          icon="VerifiedUser"
          iconColor="danger" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
          time="Now"
          isDismiss
        >
          {message}
        </Toasts>,
        {
          autoDismiss: true,
        }
      );
      navigate("/auth-pages/login");
    }
  };
  const verifNewEmail = async () => {
    const { status, message } = await verifyNewEmail({ token: authToken });
    if (status == "success") {
      addToast(
        <Toasts
          title="Success"
          icon="VerifiedUser"
          iconColor="success" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
          time="Now"
          isDismiss
        >
          {message}
        </Toasts>,
        {
          autoDismiss: true,
        }
      );
      navigate("/settings/profile-settings");
    } else {
      addToast(
        <Toasts
          title="Error"
          icon="VerifiedUser"
          iconColor="danger" // 'primary' || 'secondary' || 'success' || 'info' || 'warning' || 'danger' || 'light' || 'dark'
          time="Now"
          isDismiss
        >
          {message}
        </Toasts>,
        {
          autoDismiss: true,
        }
      );
      navigate("/auth-pages/login");
    }
  };
  useEffect(() => {
    let pathname = location.pathname;
    console.log("...pathname", pathname, authToken);
    if (authToken != "" && pathname == "/email-verification/" + authToken) {
      emailActivation();
    } else if (
      authToken != "" &&
      pathname == "/verify-old-email/" + authToken
    ) {
      verifOldEmail();
    } else if (
      authToken != "" &&
      pathname == "/verify-new-email/" + authToken
    ) {
      verifNewEmail();
    } else if (pathname == "/withdraw-coin-verification/" + authToken) {
      acceptCoinRequest();
    }
  }, [authToken]);

  return (
    <>
      {page == "loading" && <p>{"LOADING..."}</p>}
      {page == "error" && <p>{"ERROR..."}</p>}
    </>
  );
};

export default EmailVerification;
