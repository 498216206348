// import packages
import axios, { handleResp } from "../config/axios";

// import config
import config from "../config";

// import constant
import {
  SET_CURRENCY,
  SET_PRICE_CONVERSION,
  SET_REFERAL_MEMBERS_LEVEL,
} from "../constant";

// import lib
import { getAuthToken } from "../lib/localStorage";

export const getCurrency = async (dispatch: any) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/getCurrency`,
    });
    dispatch(setCurrencyOption(respData.data.result));
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getPriceConversion = async (dispatch: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/priceConversion`,
      headers: { Authorization: token },
    });
    dispatch({
      type: SET_PRICE_CONVERSION,
      data: respData.data.result,
    });
    return true;
  } catch (err: any) {
    handleResp(err, "error");
    return false;
  }
};

export const getChartData = async (pairName: any) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/get-chart-data/${pairName}`,
    });

    return {
      status: "success",
      result: respData.data.result,
      markPrice: respData.data.markPrice,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getMemLevelSetting = async (dispatch: any) => {
  try {
    const respData = await axios({
      url: `${config.API_URL}/api/member-level`,
      method: "get",
    });

    if (respData && respData.data && respData.data.status) {
      let { data } = respData.data.result;
      dispatch({
        type: SET_REFERAL_MEMBERS_LEVEL,
        data,
      });
    }
  } catch (err: any) {
    handleResp(err, "error");
    return false;
  }
};

export const getReferalStructure = async () => {
  try {
    const respData = await axios({
      url: `${config.API_URL}/api/get-referral-structure`,
      method: "get",
    });

    return {
      status: "success",
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getCms = async (identifier: any) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/cms/${identifier}`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getAllFaq = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/faq`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const setCurrencyOption = (data: any) => {
  return {
    type: SET_CURRENCY,
    data,
  };
};
