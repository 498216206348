// import package
// import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";


// import constant
import {
    SET_AUTHENTICATION,
    REMOVE_AUTHENTICATION
} from '../constant';

// import config
import config from '../config';
import { createSocketUser } from '../config/socketConnectivity';
// import { removeAuthorization } from '../config/axios'
import { removeAuthToken } from '../lib/localStorage'

// import lib
import isEmpty from "../lib/isEmpty";

export const decodeJwt = (token: string, dispatch: any) => {
    try {
        if (!isEmpty(token)) {
            token = token.replace('Bearer ', '')
            // const decoded: any = jwt.verify(token, config.secretOrKey);
            const decoded: any = jwt_decode(token, config.secretOrKey);
            if (decoded) {
                createSocketUser(decoded._id)
                dispatch({
                    type: SET_AUTHENTICATION,
                    authData: {
                        isAuth: true,
                        userId: decoded._id,
                        uniqueId: decoded.uniqueId,
                        name: decoded.name,
                        referralCode: decoded.referralCode,
                        email: decoded.email,
                        profileImage: decoded.profileImage,
                        twoFAStatus:decoded.twoFAStatus
                    }
                })
            }
        }
    } catch (err: any) {
        removeAuthToken()
        // removeAuthorization()
        // history.push('/login')
        dispatch({
            type: REMOVE_AUTHENTICATION,
            authData: {
                isAuth: false,
            }
        })
    }
}

export const tokenVerify = (token: string) => {
    try {
        if (!isEmpty(token)) {
            token = token.replace('Bearer ', '')
            const decoded: any = jwt_decode(token, config.secretOrKey);
            if (decoded) {
                return true
            }
        }
        return false
    } catch (err: any) {
        return false
    }
}