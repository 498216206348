// import package
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { socket } from '../config/socketConnectivity';

// import context
import SocketContext from '../contexts/SocketContext';

// import action
import { /*getBankDetail, getUserSetting, viewUserProfile,*/ unReadNotice, FetchunReadNotice, logout } from '../Action/user';
// import { getKycDetail } from '../../Action/userKyc';
import { getPriceConversion, getMemLevelSetting,/*getANNC,*/ getCurrency } from '../Action/commonAction';
import { getAssetData } from '../Action/walletAction';
// import { unReadNotice, FetchunReadNotice } from '../../Action/notificationAction';
// import isEmpty from '../lib/isEmpty';

const HelperRoute: any = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socketContext = useContext(SocketContext);
    // redux-state
    const { isAuth }: any = useSelector((state: any) => state.auth);
    const currencyOption: any = useSelector((state: any) => state.currency)

    // function
    useEffect(() => {
        if (isAuth) {

            // getUserSetting(dispatch)
            // getBankDetail(dispatch)
            getAssetData(dispatch)
            // viewUserProfile(dispatch)
            getPriceConversion(dispatch)
            getMemLevelSetting(dispatch)
            // getANNC(dispatch)
            unReadNotice(dispatch)
            // getKycDetail(dispatch)
        }
    }, [isAuth])

    useEffect(() => {
        if (!(currencyOption && currencyOption.length > 0)) {
            getCurrency(dispatch);
        }

        socketContext.socket.on('notice', (result: any) => {
                console.log("...result",result)
            FetchunReadNotice(dispatch, result)
        })

        socketContext.socket.on('FORCE_LOGOUT', (result: any) => {
            let token = localStorage.getItem('user_token');
            if (result && token != result) {
                logout(navigate, dispatch)
            }
        })
    }, [])

    return ''
}

export default HelperRoute;