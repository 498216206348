import React, { useLayoutEffect, forwardRef, ReactElement, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import { useNavigate, useLocation } from 'react-router-dom';
// import AuthContext from '../../contexts/authContext';
import { demoPages, dashboardMenu } from '../../menu';

// import config
import config from '../../config';

// import lib
import isLogin from '../../lib/isLogin';

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
	| ReactElement<ISubHeaderProps>[]
	| ReactElement<IPageProps>
	| ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children }, ref) => {
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${config.SITE_NAME
				}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || config.SITE_NAME || '');
		});

		// const { user } = useContext(AuthContext);
		const location = useLocation();
		const navigate = useNavigate();
		useEffect(() => {
			if (!isProtected && isLogin()) {
				navigate(`../${dashboardMenu.dashboard.path}`);
			} else if (isProtected && !isLogin()) {
				navigate(`../${demoPages.login.path}`);
			}else if(isProtected && location.pathname == '/'){
				navigate(`../${dashboardMenu.dashboard.path}`);
			}
			
			return () => { };
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
