import React, { useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import useDarkMode from '../hooks/useDarkMode';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import Button, { ButtonGroup } from '../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../components/bootstrap/Card';

// import Actions
import { getCms } from '../Action/commonAction';

const PrivacyPolicy = () => {
	/**
	 * Tour Start
	 */
	const { setIsOpen } = useTour();
	const [data, setData] = useState<any>({})

	const getCmsData = async () => {
		const { status, result } = await getCms('privacy_policy');

		if (status == 'success') {
			setData(result)
		}
	}

	useEffect(() => {

		getCmsData()
		if (localStorage.getItem('tourModalStarted') !== 'shown') {
			setTimeout(() => {
				setIsOpen(true);
				localStorage.setItem('tourModalStarted', 'shown');
			}, 3000);
		}
		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PageWrapper title="PrivacyPolicy">
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xl-12'>
						<Card id='modern' className='scroll-margin'>
							<CardHeader>
								<CardLabel>
									<CardTitle>{ data && data.title}</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div dangerouslySetInnerHTML={{__html: data && data.content }} />
							</CardBody>
						</Card>
					</div>

				</div>
			</Page>
		</PageWrapper>
	);
};

export default PrivacyPolicy;
