// import packages
import axios, { handleResp } from "../config/axios";

// import config
import config from "../config";

// import constant
import {
  REMOVE_AUTHENTICATION,
  UPDATE_2FA_STATUS,
  SET_AUTHENTICATION,
  SET_UNREAD_NOTICE,
} from "../constant";

// import Actions
import { getCurrency } from "./commonAction";
import { getAssetData } from "./walletAction";

// import lib
import {
  setAuthToken,
  removeAuthToken,
  getAuthToken,
} from "../lib/localStorage";
import { decodeJwt } from "../lib/jsonWebToken";

export const createUser = async (data: any) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/register`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {

    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const userEmailActivation = async (data: any) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/confirm-mail`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
      message: err.response.data.message,
    };
  }
};

export const getGeoInfoData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.getGeoInfo}`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const login = async (data: any, dispatch: any) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/login`,
      data,
    });
    if (respData.data.status == "TWO_FA") {
      return {
        status: "TWO_FA",
        loading: false,
        message: respData.data.message,
      };
    }

    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);
    getCurrency(dispatch);
    getAssetData(dispatch);

    return {
      status: "success",
      loading: false,
      token: respData.data.token,
      message: respData.data.message,
    };
  } catch (err: any) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const forgotPassword = async (data: any) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/forgotPassword`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err: any) {
    // handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const resetPassword = async (data: any) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/resetPassword`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    // handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const verifyMailToken = async (data: any) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/verifyMailToken`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    // handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const logout = (navigate: any, dispatch: any) => {
  removeAuthToken();
  dispatch({
    type: REMOVE_AUTHENTICATION,
    authData: {
      isAuth: false,
    },
  });
  navigate("/login");
};

export const changePassword = async (data: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/changePassword`,
      headers: { Authorization: token },
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const get2faCode = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/security/2fa`,
      headers: { Authorization: token },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const update2faCode = async (data: any, dispatch: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/api/security/2fa`,
      headers: { Authorization: token },
      data,
    });
    dispatch(update2FaStatus(respData.data.result.twoFaStatus));
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const disabled2faCode = async (data: any, dispatch: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "patch",
      url: `${config.API_URL}/api/security/2fa`,
      headers: { Authorization: token },
      data,
    });
    dispatch(update2FaStatus(respData.data.result.twoFaStatus));
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getReferalCount = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/referral-count`,
      headers: { Authorization: token },
    });

    return {
      status: "success",
      loading: false,
      count: respData.data.count,
      totalAmount: respData.data.totalAmount,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getLoginHistory = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/get-Login-history`,
      headers: { Authorization: token },
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result.data,
      count: respData.data.result.count,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getReferalBonus = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/get-referral-bonus`,
      headers: { Authorization: token },
      // params:data
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getReferalClaim = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/get-referral-claim`,
      headers: { Authorization: token },
      // params:data
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getReferalChart = async (params: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/get-referral-chart`,
      headers: { Authorization: token },
      params,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const getReportData=async()=>{
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/get-report`,
      headers: { Authorization: token },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
}


export const getReferalPieChart = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/referral-count`,
      headers: { Authorization: token },
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.totalAmount,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const getRewardSplit = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/get-reward-split`,
      headers: { Authorization: token },
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const showRefUser = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/show-refUser-details`,
      headers: { Authorization: token },
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const refClaimReq = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/referral-claim-request`,
      headers: { Authorization: token },
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const update2FaStatus = (data: any) => {
  return {
    type: UPDATE_2FA_STATUS,
    data,
  };
};

export const sendNwesLetter = async (data: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/newsLetter/subscribe`,
      headers: { Authorization: token },
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    console.log("...11", err);
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getProfile = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/userProfile`,
      headers: { Authorization: token },
    });

    console.log("...profile",respData.data.result)
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const profileUpload = async (data: any, dispatch: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/api/userProfile`,
      headers: { Authorization: token },
      data,
    });

    let { profileImage } = respData.data.result;

    dispatch({
      type: SET_AUTHENTICATION,
      authData: {
        profileImage: profileImage,
      },
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const updateNotify = async (data: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/update-notification-settings`,
      headers: { Authorization: token },
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getNotifySettings = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/get-notification-settings`,
      headers: { Authorization: token },
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const unReadNotice = async (dispatch: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/unread-notice`,
      headers: { Authorization: token },
    });
    dispatch({
      type: SET_UNREAD_NOTICE,
      notice: respData.data.result.NoticeData,
      notifiyCount: respData.data.result.notifiyCount,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const updateNotifyCount = async (dispatch: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "patch",
      url: `${config.API_URL}/api/unread-notice`,
      headers: { Authorization: token },
    });

    dispatch({
      type: SET_UNREAD_NOTICE,
      notice: respData.data.result.NoticeData,
      notifiyCount: respData.data.result.notifiyCount,
    });
    return {
      status: respData.data.success,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: false,
    };
  }
};

export const clearAllNotification = async (dispatch: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/api/unread-notice`,
      headers: { Authorization: token },
    });
    dispatch({
      type: SET_UNREAD_NOTICE,
      notice: respData.data.result.NoticeData,
      notifiyCount: respData.data.result.notifiyCount,
    });
    return {
      status: respData.data.success,
      result: respData.data.result,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: false,
    };
  }
};
export const FetchunReadNotice = async (dispatch: any, data: any) => {
  try {
    dispatch({
      type: SET_UNREAD_NOTICE,
      notice: data.NoticeData,
      notifiyCount: data.notifiyCount,
    });
  } catch (err: any) {}
};

export const editEmail = async (data: any, dispatch: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/api/emailChange`,
      headers: { Authorization: token },
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      // result: respData.data.result
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const verifyOldEmail = async (data: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/api/emailChange`,
      headers: { Authorization: token },
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const verifyNewEmail = async (data: any) => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "patch",
      url: `${config.API_URL}/api/emailChange`,
      headers: { Authorization: token },
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const getBankTrnsHistory = async () => {
  try {
    let token: any = await getAuthToken();
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/api/token-request`,
      headers: { Authorization: token },
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result.data,
      count: respData.data.result.count,
    };
  } catch (err: any) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};